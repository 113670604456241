/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"
import { Link } from "gatsby"

import { Layout, Seo } from "../layout"
import { FindOutMoreButton } from "../components"

export default function IndexPage(props) {
  const main_cell = "px-3 sm:px-8 py-3 text-xs sm:text-lg"
  const main_cell_2 = main_cell + " bg-gray-200"

  return (
    <Layout path={props.path}>
      <Seo title="Sales" />

      <table className="table-auto mt-32 my-24 mx-auto border border-gray-400 shadow">
        <tbody>
          <tr>
            <td className={main_cell}>
              <Link to="/products/presence#service">
                Add your business to the Trailguide App
              </Link>
            </td>
            <td className={main_cell + " text-right"}>€400/year</td>
            <td className={main_cell + " text-center"}>
              <FindOutMoreButton href="https://trailguide.net/order">
                Order here
              </FindOutMoreButton>
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>
              <Link to="/products/presence#resort">
                Add your tourist resort to the Trailguide App
              </Link>
            </td>
            <td className={main_cell_2 + " text-right"}>€800/year</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell}>
              <Link to="/products/presence#destination">
                Add your tourist destination to the Trailguide App
              </Link>
            </td>
            <td className={main_cell + " text-right"}>€1500/year</td>
            <td className={main_cell}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>
              <Link to="/products/presence#region">
                Add your tourist region to the Trailguide App
              </Link>
            </td>
            <td className={main_cell_2 + " text-right"}>€3000/year</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell}>
              <Link to="/products/plugin">Trailguide Integration Module</Link>
            </td>
            <td className={main_cell + " text-right"}>€500/year/domain</td>
            <td className={main_cell}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>
              <Link to="/products/landingpage">Trailguide Landing Page</Link>
            </td>
            <td className={main_cell_2 + " text-right"}>€500/year</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell}>
              <Link to="/products/#wall_map">Printed Wall Map</Link>
            </td>
            <td className={main_cell + " text-right"}>€2000</td>
            <td className={main_cell}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>
              <Link to="/products/#activity_manager">
                Activity Manager Module
              </Link>
            </td>
            <td className={main_cell_2 + " text-right"}>€500/year</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell}>
              <Link to="/products/#collection_manager">
                Collection Manager Module
              </Link>
            </td>
            <td className={main_cell + " text-right"}>€500/year</td>
            <td className={main_cell}>
              <EmailUs />
            </td>
          </tr>

          <tr>
            <td className={main_cell_2}>Extra Trailguide Admin User</td>
            <td className={main_cell_2 + " text-right"}>€200/user/year</td>
            <td className={main_cell_2}>
              <EmailUs />
            </td>
          </tr>
        </tbody>

        <tr>
          <td className={main_cell}>
            <Link to="https://shop.trailguide.no/">Online map store</Link>
          </td>
        </tr>
      </table>
    </Layout>
  )
}

function EmailUs() {
  return (
    <>
      sales
      <span className="hidden">If you see this, apply for a job</span>@
      <span className="hidden">as a programmer at Trailguide</span>
      trailguide.no
    </>
  )
}
